<template>
  <a-select style="width: 120px;" v-model="valueComputed" :placeholder="f.placeholder">
    <a-select-option v-for="o in options" :key="o.value">{{o.name}}</a-select-option>
  </a-select>
</template>

<script>
import { computed, onMounted, ref } from '@vue/composition-api'
export default {
  name: 'SelectField',
  props: {
    f: Object,
    value: null,
  },
  emits: ['input'],
  setup (props, { emit }) {
    const isArray = Array.isArray(props.f.options)
    const options = ref(isArray ? props.f.options : [])
    onMounted(async () => {
      if (!isArray) {
        options.value = await props.f.options()
      }
    })

    const valueComputed = computed({
      get () {
        return props.value
      },
      set (v) {
        emit('input', v)
      }
    })

    return {
      options,
      valueComputed,
    }
  }
}
</script>

<style lang="css" scoped>

</style>
