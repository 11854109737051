<template>
  <a-select
    show-search
    allow-clear
    :value="value"
    :placeholder="f.placeholder"
    style="width: 120px"
    :default-active-first-option="false"
    :show-arrow="false"
    :filter-option="false"
    :not-found-content="null"
    @search="handleSearch"
    @change="handleChange"
  >
    <a-select-option v-for="item in data" :key="item.value">{{item.name}}</a-select-option>
  </a-select>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'

export default {
  name: 'SearchField',
  props: ['value', 'f'],
  emits: ['input'],
  setup (props, { emit }) {
    const data = ref([])
    let current

    onMounted(() => {
      if (data.value.length === 0) {
        handleSearch()
      }
    })

    async function handleSearch (value) {
      current = value
      const serverData = await props.f.search(value)
      if (current === value) {
        data.value = serverData
      }
    }

    function handleChange (value) {
      emit('input', value)
    }

    return {
      handleSearch,
      handleChange,
      data,
    }
  }
}
</script>

<style lang="css" scoped>

</style>
